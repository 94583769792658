import DOMPurify from "dompurify";
import { escapeRegExp, escape } from "lodash";
import moment from "moment/moment";

export const numberWithSpaces = (number) => {
	if (!number) {
		return number;
	}

	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatDelimitedNumber = (number) =>
	number ? parseFloat(number).toLocaleString("en-GB") : number;

export const formatNumber = (number, options = null) => {
	return number
		? new Intl.NumberFormat("en-US", options).format(number)
		: number;
};

export const getNewsDate = (date) =>
	moment.utc(date).format("DDMMMYYYY HH:mm UTC");

export const trim = (string, length = 15) => {
	return string.length > length
		? string.substring(0, length - 3) + "..."
		: string;
};

export const visibleOnUserInput = (label, input) => {
	return label.toLowerCase().includes(input.toLowerCase());
};

export function convertDecimalHoursToHrsMins(hours) {
	let h = Math.floor(hours);
	let m = Math.round((hours - h) * 60);

	if (String(h).length < 2) {
		h = "0" + h;
	}

	if (String(m).length < 2) {
		m = "0" + m;
	}

	return `${h}:${m}`;
}

export function buildYearOptions(from, to) {
	let options = [];
	for (let i = to; i >= from; i--) {
		options.push({ key: i, value: i.toString() });
	}
	return options;
}

export function buildYearChoices(from, to) {
	let options = [];
	for (let i = to; i >= from; i--) {
		options.push({ key: i, value: i.toString() });
	}
	return options;
}

export function buildMonthOptions() {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	return months.map((month, index) => ({ key: index, value: month }));
}

export const buildHourOptions = () => {
	const hours = [];
	for (let i = 1; i < 24; i++) {
		hours.push({ key: i.toString(), value: `${i < 10 ? `0${i}` : i}:00` });
	}
	return hours;
};

export function getChoicesEndpoint(endpoint, base = "/api/v1/choices/") {
	return `${base}${endpoint}`;
}

export const flattenCriteria = (criteria) =>
	criteria.reduce((obj, group) => ({ ...obj, ...group.criteria }), {});

export function getAllCriteria(criteria) {
	return Object.entries(flattenCriteria(criteria)).map(([key, value]) =>
		value.factory(key),
	);
}

export const getCriteriaToFilter = (criteria) =>
	getAllCriteria(criteria).map((item) => {
		const key = item.props.criterionKey;
		const criteria = item.props.criteria;
		const isComposite = !key && criteria.length > 0;

		if (isComposite) {
			return criteria.map((criterion) => ({
				key: criterion.props.criterionKey,
				label: criterion.props.label,
			}));
		}

		return { key, label: item.props.label };
	});

export const getSchedulesPeriod = () => {
	const start = moment().add(4, "days");
	return {
		startDate: start.format("YYYY-MM-DD"),
		endDate: start.add(1, "weeks").format("YYYY-MM-DD"),
	};
};

export const scrollFootnotesIntoView = () => {
	const footnotesDiv = document.getElementsByClassName("footnotes")[0];
	if (footnotesDiv) {
		footnotesDiv.scrollIntoView(false);
	}
};

export const sanitizeHTML = (html) => DOMPurify.sanitize(html);

export const sanitizeString = (data) => escape(escapeRegExp(data));
